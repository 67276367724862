import styled from 'styled-components';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import GitHubIcon from '@mui/icons-material/GitHub';
import TelegramIcon from '@mui/icons-material/Telegram';
import EmailIcon from '@mui/icons-material/Email';
import { Bio } from '../../data/constants';
import { Snackbar } from '@mui/material';
import { toast } from 'react-toastify';
import MuiAlert from '@mui/material/Alert';
import { useState } from 'react';
import { AiOutlineFile } from 'react-icons/ai';




const FooterContainer = styled.div`
  width: 100%;
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  //background: linear-gradient(100.26deg, rgba(0, 102, 255, 0.05) 42.33%, rgba(150, 0, 225, 0.05) 127.07%);
`;


const FooterWrapper = styled.footer`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: center;
  padding: 1rem;
  color: ${({ theme }) => theme.text_primary};
`;

const Logo = styled.h1`
  font-weight: 600;
  font-size: 25px;
  font-weight: bold;
  background: linear-gradient(225deg, hsla(330, 100%, 50%, 1) 0%, hsla(270, 100%, 50%, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  
`;

const Nav = styled.nav`
  width: 100%;
  max-width: 800px;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: center;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    text-align: center;
    font-size: 12px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    text-align: center;
    font-size: 12px;
  }
`;

const NavLink = styled.a`
color: ${({ theme }) => theme.text_primary};
  text-decoration: none;
  font-size: 1.2rem;
  transition: color 0.2s ease-in-out;
  &:hover {
    background: linear-gradient(225deg, hsla(330, 100%, 50%, 1) 0%, hsla(270, 100%, 50%, 1) 100%);
 -webkit-background-clip: text;
 -webkit-text-fill-color: transparent;
}
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const SocialMediaIcons = styled.div`
  display: flex;
  margin-top: 1rem;
`;

const SocialMediaIcon = styled.a`
  display: inline-block;
  margin: 0 0.8rem;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.text_primary};
  transition: color 0.2s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.primary};
    transform: translateY(-2px);
  }
`;


const Copyright = styled.p`
  margin-top: 1.5rem;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.soft2};
  text-align: center;
`;


function Footer() {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    navigator.clipboard.writeText('naveenkumar59835@gmail.com');
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  
  return (
    <FooterContainer>
      <FooterWrapper>
    
        <Logo>NAVEEN KUMAR</Logo>
        <Nav>
          <NavLink href="/">Home</NavLink>
          <NavLink href="#about">About</NavLink>
          <NavLink href="#skills">Skills</NavLink>
          <NavLink href="#experience">Experience</NavLink>
          <NavLink href="#projects">Projects</NavLink>
          <NavLink href="#education">Education</NavLink>
          <NavLink href='#certifications'>Certifications</NavLink>
          <NavLink href='#contact'>Contact</NavLink>
        </Nav>
      
        <SocialMediaIcons>
  <SocialMediaIcon href="https://www.linkedin.com/in/naveenkumarans/" target="_blank" title="LinkedIn">
    <LinkedInIcon />
  </SocialMediaIcon>
  <SocialMediaIcon href="https://github.com/naveenkumarans" target="_blank" title="GitHub">
    <GitHubIcon />
  </SocialMediaIcon>
  <SocialMediaIcon href="https://www.instagram.com/freakin__jerry/" target="_blank" title="Instagram">
    <InstagramIcon />
  </SocialMediaIcon>
  <SocialMediaIcon href="mailto:naveenkumar59835@gmail.com" target="_blank" title="Email Me">
    <EmailIcon />
  </SocialMediaIcon>
  <SocialMediaIcon href="https://t.me/naveenks1" target="_blank" title="Telegram">
    <TelegramIcon />
  </SocialMediaIcon>
  <SocialMediaIcon href="https://drive.google.com/file/d/1wHJoK8ce9k_nfh7lO4s6z2k6XX0berFz/view?usp=sharing" target="_blank" title="My Resume">
    <AiOutlineFile size={20} style={{ strokeWidth: 40 }} />
  </SocialMediaIcon>
</SocialMediaIcons>

          <h5 style={{ fontSize: 'large' }}>
          Contact Info :
          <span
            onClick={handleClick}
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            title="Click to Copy"
          >
            <i className="fas fa-envelope" style={{ marginRight: '5px' }}></i>
            naveenkumar59835@gmail.com
          </span>
        </h5>

        <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
          <MuiAlert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Copied to clipboard!
          </MuiAlert>
        </Snackbar>
        <Copyright>
             &copy; 2025 Naveen Kumar. All rights reserved.
        </Copyright>
        
      </FooterWrapper>
    </FooterContainer>
    
  );
}

export default Footer;