// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import { PostHogProvider} from 'posthog-js/react'

const options = {
  api_host: "https://us.i.posthog.com",
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <PostHogProvider 
      apiKey="phc_XuoQezdjfrxTnhRJXkvzvu2BnU0XLWv2Pv0I8Ibsh4l"
      options={options}
    >
      <App />
    </PostHogProvider>
  </React.StrictMode>
);
